.cl_related {
  padding: 68px 0 72px;
}
.cl_related .cl_centrer h2 {
  margin-bottom: 36px;
}
.cl_related .cl_centrer .course_related {
  margin: 0;
}
.cl_related .cl_centrer .course_related h5 {
  display: none;
}
.cl_related .cl_centrer .course_related .feed_block_outer {
  max-width: 1170px !important;
}
.cl_related .cl_centrer .course_related .feed_block_outer .feed_block {
  margin-left: 0px;
}
.cl_related .cl_centrer .course_related .feed_block_outer .feed_block .course_preview_v2 {
  width: 270px;
  margin: 0 15px;
}
.cl_related .cl_centrer .course_related .feed_block_outer .feed_block .course_preview_v2 .img_wrapper {
  height: 175px;
}
@media screen and (max-width: 1180px) {
  .cl_related {
    overflow: hidden;
  }
}
@media screen and (max-width: 700px) {
  .cl_related {
    padding-top: 40px;
  }
  .cl_related .feed_block_outer .arr {
    display: none;
  }
}